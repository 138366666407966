import {computed, inject, Injectable, signal} from '@angular/core';
import {NotificationDto} from "../../../core/common/models/dtos/notification";
import {AchievementDto} from "../models/dtos/achievement";
import {AchievementNotificationDto} from "../models/dtos/achievement-notification";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom, lastValueFrom} from "rxjs";
import {UserService} from "../../../core/auth/services/user.service";
import {
  ResponseWrapperDto,
  ResponseWrapperWithContentDto
} from "../../../core/common/models/interfaces/response-wrapper";

@Injectable({
  providedIn: 'root'
})
export class AchievementsService {
  readonly isNotificationVisible = computed(() => this.isNotificationVisibleSignal());
  readonly achievement = computed(() => this.achievementSignal());
  private isNotificationVisibleSignal = signal(false);
  private achievementSignal = signal<AchievementDto | null>(null);
  private baseUrl = 'api/Achievements/';
  private http = inject(HttpClient);
  private userService = inject(UserService);

  constructor() { }

  public showAchievementNotification(notification: NotificationDto) {
    const achievementNotification = notification as AchievementNotificationDto;
    if (!achievementNotification.achievement) {
      return;
    }
    this.achievementSignal.set(achievementNotification.achievement);
    this.isNotificationVisibleSignal.set(true);
  }

  public async hideAchievementNotification(): Promise<void> {
    this.isNotificationVisibleSignal.set(false);
    const result = await this.getUserAchievements();
    if (result.content?.length) {
      this.userService.refreshUserAchievements(result.content);
    }
    this.achievementSignal.set(null);
  }

  public async getUserAchievements(): Promise<ResponseWrapperWithContentDto<AchievementDto[]>> {
    return await lastValueFrom(this.http.get<ResponseWrapperWithContentDto<AchievementDto[]>>(this.baseUrl + 'GetUserAchievements'));
  }
}
